<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div *ngIf="!isPreview" class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">{{ programId ? 'Edit' : 'Add' }} Program</h3>
        <div *ngIf="programId && !isPreview" class="row">
          <div class="col-12">
            <alert class="text-center" type="info" [dismissible]="true">
              <div>
                <span class="font-weight-bold">Note: Editing a program will not update any instances of the program that have already been added to an athlete's calendar.</span>
              </div>
            </alert>
          </div>
        </div>
        <h3 *ngIf="!showGeneralSection" class="d-block">{{formGroup.get('name').value}}</h3>
        <a (click)="showGeneralSection = !showGeneralSection" href="javascript:void(0)" class="ml-2 toggle-link">{{ showGeneralSection ? 'Hide' : 'Show' }} More Details</a>
      </div>
    </div>
    <div *ngIf="isPreview" class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Preview Program</h3>
        <div class="float-right">
          <a href="javascript:void(0)" (click)="onAssign()" class="btn btn-primary">Assign to Athlete</a>
          <a href="javascript:void(0)" (click)="onAssignToTeam()" class="btn btn-primary">Assign to Team</a>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div *ngIf="!isPreview" [hidden]="!showGeneralSection">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label" for="name"> Program Name </label>
                  <input class="form-control" formControlName="name" maxlength="100" type="text" />
                  <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.required">
                    <div>Name is required</div>
                  </div>
                  <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.nameTaken">Program with this name already exists</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label" for="programCategory"> Categories </label>
                  <kendo-multiselect [valuePrimitive]="true" [textField]="'name'" [valueField]="'id'" [data]="allProgramCategories" formControlName="programCategory"></kendo-multiselect>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label" for="programKeywords"> Keywords </label>
                  <div class="d-inline text-center" kendoTooltip>
                    <i class="fas fa-info-circle ml-1"
                      title="Select an existing keyword or type to create a new keyword."></i>
                  </div>
                  <kendo-multiselect [data]="keywordsList" formControlName="programKeywords" [allowCustom]="true"></kendo-multiselect>
                  <div class="invalid-message">At least 1 keyword required for Paid Programs</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label" for="videoLink"> Video Type </label>
                  <select formControlName="videoType" class="form-control">
                    <option value="2">Public (YouTube, Vimeo, etc)</option>
                    <option value="1">Uploaded</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div *ngIf="formGroup.controls.videoType.value == 2" class="form-group">
                  <label class="form-control-label" for="videoLink"> Link to Video </label>
                  <input type="text" class="form-control" formControlName="videoLink" maxlength="1000" placeholder="YouTube/Vimeo Link" aria-label="Recipient's username" aria-describedby="button-addon2" />
                </div>
                <div *ngIf="formGroup.controls.videoType.value == 1" class="form-group">
                  <video-upload formControlName="videoId" [label]="'Upload Video'"></video-upload>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label" for="description"> Description </label>
                  <custom-editor formControlName="description"></custom-editor>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label class="form-control-label mr-1" for="isHidden">
                    Is Archived
                  </label>
                  <kendo-switch formControlName="isHidden"></kendo-switch>
                </div>
              </div>
            </div>
            <div *ngIf="hasMarketplace && !program.createdFromRunDNAClone" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label mr-1" for="isPaidProgram">
                    Is Paid Program
                  </label>
                  <div class="d-inline text-center" kendoTooltip>
                    <i class="fas fa-info-circle mr-2"
                      title="If this is selected, this program will be included on your organization's Marketplace page."></i>
                  </div>
                  <kendo-switch class="d-flex" formControlName="isPaidProgram" (valueChange)="onPaidProgramChange()"></kendo-switch>
                </div>
              </div>
              <div *ngIf="formGroup.get('isPaidProgram').value == true" class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="price"> Program Price </label>
                  <kendo-numerictextbox noScroll class="form-control d-block medium-numeric" formControlName="price" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number" ></kendo-numerictextbox>
                  <div class="invalid-message">
                    <div>Must meet minimum price of ${{minPaidProgramPrice}}</div>
                  </div>
                </div>
              </div>
              <div *ngIf="formGroup.get('isPaidProgram').value == true" class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="userId"> Written By </label>
                  <coaches-dropdown class="form-control" formControlName="userId"></coaches-dropdown>
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('isPaidProgram').value == true" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label mr-1" for="showInGlobalMarketplace">
                    Include in Global Marketplace
                  </label>
                  <div class="d-inline text-center" kendoTooltip>
                    <i class="fas fa-info-circle mr-2"
                      title="If this is selected, this program will also be listed on RunDNA's Marketplace page."></i>
                  </div>
                  <kendo-switch class="d-flex" formControlName="showInGlobalMarketplace" (valueChange)="onGlobalMarketplaceChange()"></kendo-switch>
                </div>
              </div>
              <div *ngIf="formGroup.get('showInGlobalMarketplace').value == true" class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="globalMarketplaceProgramType">
                    Global Marketplace Program Type
                  </label>
                  <select class="form-control" formControlName="globalMarketplaceProgramType">
                    <option [ngValue]="null">(none)</option>
                    <ng-container *ngFor="let mapEntry of globalMarketplaceProgramTypeEnum | keyvalue">
                      <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-message">
                    <div>Program Type is required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="formGroup.get('showInGlobalMarketplace').value == true" class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="globalMarketplaceExperienceLevel">
                    Global Marketplace Experience Level
                  </label>
                  <select class="form-control" formControlName="globalMarketplaceExperienceLevel">
                    <option [ngValue]="null">(none)</option>
                    <ng-container *ngFor="let mapEntry of globalMarketplaceExperienceLevelEnum | keyvalue">
                      <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-message">
                    <div>Experience Level is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isPreview && programId" class="row form-btns">
            <div class="col">
            </div>
            <div  class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Program</button>
            </div>
          </div>
          <div *ngIf="isPreview">
            <div class="row">
              <div class="col-md-12">
                <h3>{{ program.name }}</h3>
              </div>
              <div *ngIf="programKeywords.length > 0" class="col-md-12 mb-2">
                <span class="font-weight-bold">Keywords: </span>
                <span *ngFor="let keyword of programKeywords; let last = last">
                  <span>{{ keyword }}{{ last ? '' : ', ' }}</span>
                </span>
              </div>
              <div *ngIf="program.programCategory" class="col-md-12 mb-2">
                <span class="font-weight-bold">Categories: </span>
                <span>{{ program.programCategory | flagEnumString: programCategoryEnum }}</span>
              </div>
              <div *ngIf="program.videoLink && program.videoType == 2" class="col-md-6 m-2">
                <embed-video-player [url]="program.videoLink" [size]="'small'"></embed-video-player>
              </div>
              <div *ngIf="program.videoType == 1" class="col-md-6 m-2">
                <embed-video-player [videoId]="program.videoId"></embed-video-player>
              </div>
            </div>
            <div class="col-md-12">
              <span [editorHTML]="program.description"></span>
            </div>
            <div *ngIf="hasMarketplace" class="row mb-2">
              <div class="col-md-4">
                <span class="font-weight-bold">Is Paid Program: </span>
                <span>{{ program.isPaidProgram | booleanToYesNo }}</span>
              </div>
              <div *ngIf="program.isPaidProgram == true" class="col-md-4">
                <span class="font-weight-bold">Price: </span>
                <span>${{ program.price | number: '1.2-2'}}</span>
              </div>
            </div>
          </div>

          <a class="toggle-link" (click)="showPeriods = !showPeriods" href="javascript:void(0)">{{ showPeriods ? 'Hide' : 'Show' }} Training Load Periods</a>
          <div class="mb-3" [hidden]="!showPeriods">
            <table class="table table-sm w-auto">
              <thead class="table-bordered">
                <tr>
                  <th>Period</th>
                  <th>Training Load Progression</th>
                  <th>RPE 1-4 %</th>
                  <th>RPE 5-6 %</th>
                  <th>RPE 7-8 %</th>
                  <th>RPE 9-10 %</th>
                </tr>
              </thead>
              <tbody class="table-bordered">
                <tr *ngFor="let period of periods; let c = index">
                  <td>{{ period.trainingLoadPeriodType | enumString: trainingLoadPeriodEnum }}</td>
                  <td class="text-right">{{ period.trainingLoadMultiplier * 100 | number : '1.0-0' }}%</td>
                  <td class="text-right">{{ period.rpE1To4Multiplier * 100 | number : '1.0-0' }}%</td>
                  <td class="text-right">{{ period.rpE5To6Multiplier * 100 | number : '1.0-0' }}%</td>
                  <td class="text-right">{{ period.rpE7To8Multiplier * 100 | number : '1.0-0' }}%</td>
                  <td class="text-right">{{ period.rpE9To10Multiplier * 100 | number : '1.0-0' }}%</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <a (click)="showWeeklyDurations = !showWeeklyDurations" href="javascript:void(0)" class="ml-2 align-bottom toggle-link d-block">{{ showWeeklyDurations ? 'Hide' : 'Show' }} Weekly Volumes</a> -->
          <div class="card card-calendar pb-1">
            <div *ngIf="!isPreview">
              <button type="button" (click)="addWeek(0)" class="btn btn-primary btn-sm mt-1 ml-1">Add Week</button>
            </div>
            <div class="card-wrapper" *ngFor="let week of weeks; let w = index">
              <div class="card-header">
                <div class="row">
                  <div class="col-lg-3">
                    <div *ngIf="!isPreview" class="dropdown no-caret" dropdown>
                      <a aria-controls="dropdown-basic" class="btn btn-sm btn-icon-only dropdown-toggle" dropdownToggle href="javascript:void(0)" role="button">
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow" *dropdownMenu>
                        <a href="javascript:void(0)" (click)="onCloneWeek(week.weekNumber)" class="dropdown-item"> Clone Week </a>
                        <a href="javascript:void(0)" (click)="onDeleteWeek(week.weekNumber)" class="dropdown-item"> Delete Week </a>
                        <a *ngIf="week.weekNumber < weeks.length" href="javascript:void(0)" (click)="onMoveWeek(week.weekNumber, 1)" class="dropdown-item"> Move Week Down </a>
                        <a *ngIf="week.weekNumber > 1" href="javascript:void(0)" (click)="onMoveWeek(week.weekNumber, -1)" class="dropdown-item"> Move Week Up </a>
                      </div>
                    </div>
                    <h5 class="h3 mb-0">Week {{ week.weekNumber }}</h5>
                    <div class="">
                      <label class="form-control-label pr-1" for="trainingLoadPeriodType"> Period </label>
                    <select #period [disabled]="isPreview" class="form-control d-inline w-auto" [(ngModel)]="week.trainingLoadPeriodType" (ngModelChange)="onPeriodTypeChange($event, period, week.weekNumber)" [ngModelOptions]="{standalone: true}">
                      <option [ngValue]="null">(none)</option>
                      <ng-container *ngFor="let mapEntry of trainingLoadPeriodEnum | keyvalue">
                        <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                      </ng-container>
                    </select>
                    </div>
                  </div>
                  <div class="col-lg-9">
                    <table class="table table-sm">
                      <thead class="table-bordered">
                        <tr>
                          <th></th>
                          <th>Training Load</th>
                          <ng-container *ngIf="week.trainingLoadPeriodType && week.weekNumber != 1">
                            <th>Low</th>
                            <th>High</th>
                          </ng-container>
                        </tr>
                      </thead>
                      <tbody class="table-bordered">
                        <tr class="font-italic" *ngIf="week.trainingLoadPeriodType  && week.weekNumber != 1">
                          <td>SUGGESTED</td>
                          <td class="text-right">{{week.suggestedTrainingLoad}}</td>
                          <td class="text-right">{{week.trainingLoadLow}}</td>
                          <td class="text-right">{{week.trainingLoadHigh}}</td>
                        </tr>
                        <tr>
                          <td>PLANNED</td>
                          <td class="text-right">{{week.scheduledTrainingLoad}}</td>
                          <ng-container *ngIf="week.trainingLoadPeriodType  && week.weekNumber != 1">
                            <td></td>
                            <td></td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-sm">
                      <thead class="table-bordered">
                        <tr>
                          <th>RPE 1-4</th>
                          <th>RPE 5-6</th>
                          <th>RPE 7-8</th>
                          <th>RPE 9-10</th>
                        </tr>
                      </thead>
                      <tbody class="table-bordered">
                        <tr *ngIf="week.trainingLoadPeriodType  && week.weekNumber != 1" class="font-italic">
                          <td class="text-right">{{week.rpE1To4Load}}</td>
                          <td class="text-right">{{week.rpE5To6Load}}</td>
                          <td class="text-right">{{week.rpE7To8Load}}</td>
                          <td class="text-right">{{week.rpE9To10Load}}</td>
                        </tr>
                        <tr>
                          <td class="text-right">{{week.scheduledRPE1To4Load}}</td>
                          <td class="text-right">{{week.scheduledRPE5To6Load}}</td>
                          <td class="text-right">{{week.scheduledRPE7To8Load}}</td>
                          <td class="text-right">{{week.scheduledRPE9To10Load}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- <table *ngIf="week.cardioExerciseDurations.length > 1 && showWeeklyDurations" class="table table-sm">
                  <thead class="table-bordered">
                    <tr>
                      <th>Exercise Type</th>
                      <th>Planned Time</th>
                      <th>Planned Miles</th>
                      <th>Planned Kilometers</th>
                    </tr>
                  </thead>
                  <tbody class="table-bordered">
                    <tr *ngFor="let duration of week.cardioExerciseDurations; let c = index">
                      <td>{{ duration.cardioExerciseType ? (duration.cardioExerciseType | enumString: cardioExerciseTypeEnum) : 'TOTAL' }}</td>
                      <td class="text-right">{{ duration.expectedTime | secsToHourMinSec }}</td>
                      <td class="text-right">{{ duration.expectedMiles }}</td>
                      <td class="text-right">{{ duration.expectedKilometers }}</td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
              <div class="card-body p-0">
                <div data-toggle="calendar" id="calendar" class="calendar fc fc-ltr fc-unthemed" style="">
                  <div class="fc-view-container">
                    <div class="fc-dayGridMonth-view fc-dayGrid-view" style="">
                      <table class="">
                        <thead class="fc-head">
                          <tr>
                            <td class="fc-head-container fc-widget-header">
                              <div class="fc-row fc-widget-header">
                                <table class="">
                                  <thead>
                                    <tr>
                                      <th *ngFor="let day of week.programDays; let d = index" (click)="!isPreview && onDayClick($event, day.calendarDate, w, d)">
                                        <span>Day {{ day.weekDayNumber }}</span>
                                      </th>
                                      <th>
                                        <span>Totals</span>
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody class="fc-body">
                          <tr>
                            <td class="fc-widget-content">
                              <div class="" style="">
                                <div class="fc-day-grid">
                                  <div class="fc-row fc-week fc-widget-content" style="min-height: 125px">
                                    <div class="fc-bg">
                                      <table class="">
                                        <tbody>
                                          <tr>
                                            <td *ngFor="let day of week.programDays; let d = index" class="fc-day fc-widget-content fc-other-month fc-past"></td>
                                            <td class="fc-day fc-widget-content fc-other-month fc-past"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="fc-content-skeleton" style="min-height: 125px">
                                      <table style="height: 100%; min-height: 125px">
                                        <tbody>
                                          <tr>
                                            <td *ngFor="let day of week.programDays; let d = index" (click)="!isPreview && onDayClick($event, day.calendarDate, w, d)" class="custom-event-container">
                                              <ng-container *ngIf="!isPreview">
                                                <kendo-sortable [kendoSortableBinding]="day.workoutDays" class="d-flex flex-column" [emptyText]="''" [emptyItemStyle]="{ height: '100%' }" [activeItemStyle]="{ opacity: '0.7', border: '2px dashed black' }" (dragStart)="onDragStart($event, w, d)" (dragEnd)="onDragEnd($event, w, d)" zone="weekZone">
                                                  <ng-template let-event="item" let-e="index">
                                                    <div (click)="onEventClick($event, event)" class="custom-event" [ngClass]="{ 'selected': (event.dayNumber == selectedWorkoutDayDayNumber && event.sortOrder == selectedWorkoutDaySortOrder) }">
                                                      <div class="pl-1 event-activity-type">
                                                        <i class="far {{ (event.workout ? event.workout.singleActivityType : (event.eventType == eventType.QuickWorkout ? event.quickWorkoutActivityType : null)) | customCalendarEventIcon: event.eventType:(event.workout ? event.workout.workoutType : null) }}"></i>
                                                        <ng-container *ngIf="event.workout">
                                                          <span *ngIf="event.workout.expectedTime != null">{{ event.workout.expectedTime | secsToHourMinOnly }}</span>
                                                          <span *ngIf="(event.workout.expectedMiles || event.workout.expectedKilometers)">{{ ((event.workout.expectedMiles | milesKilometersToMiles: event.workout.expectedKilometers) | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                                                          <span class="d-block">{{event.workout.expectedTrainingLoad}} (load)</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="event.eventType == eventType.QuickWorkout">
                                                          <span *ngIf="event.quickWorkoutDuration != null">{{ event.quickWorkoutDuration | secsToHourMinOnly }}</span>
                                                          <span *ngIf="event.quickWorkoutDistance">{{ (event.quickWorkoutDistance | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                                                          <span class="d-block">{{event.quickWorkoutExpectedTrainingLoad}} (load)</span>
                                                        </ng-container>
                                                      </div>
                                                      <div class="custom-event-title-wrapper">
                                                        <span class="event-title">{{ event.workoutName }}</span>
                                                      </div>
                                                      <div *ngIf="event.dayNumber == selectedWorkoutDayDayNumber && event.sortOrder == selectedWorkoutDaySortOrder" (clickOutside)="onClickedOutsideMenu($event)" class="custom-event-actions d-flex justify-content-around align-items-center">
                                                        <a *ngIf="event.workout" href="javascript:void(0)" (click)="onPreviewWorkout(event.workout.id)">
                                                          <i class="fas fa-info-circle"></i>
                                                        </a>
                                                        <a *ngIf="event.eventType == eventType.QuickWorkout" href="javascript:void(0)" (click)="onPreviewQuickWorkout(event)">
                                                          <i class="fas fa-info-circle"></i>
                                                        </a>
                                                        <a href="javascript:void(0)" (click)="onOpenWorkoutDialog($event, w, d, event.sortOrder)">
                                                          <i class="fas fa-pencil"></i>
                                                        </a>
                                                        <a *ngIf="event.workout || eventType.QuickWorkout" href="javascript:void(0)" (click)="onCopyEvent($event, event)">
                                                          <i class="fas fa-copy"></i>
                                                        </a>
                                                        <a href="javascript:void(0)" (click)="onDeleteEvent($event, w, d, e)">
                                                          <i class="fas fa-times"></i>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </ng-template>
                                                </kendo-sortable>
                                              </ng-container>
                                              <ng-container *ngIf="isPreview">
                                                <div *ngFor="let event of day.workoutDays; let e = index" class="custom-event-preview">
                                                  <div (click)="(event.workout && onPreviewWorkout(event.workout.id)) || (event.eventType == eventType.QuickWorkout && onPreviewQuickWorkout(event))" class="custom-event">
                                                    <div class="pl-1 event-activity-type">
                                                      <i class="far {{ (event.workout ? event.workout.singleActivityType : (event.eventType == eventType.QuickWorkout ? event.quickWorkoutActivityType : null)) | customCalendarEventIcon: event.eventType:(event.workout ? event.workout.workoutType : null) }}"></i>
                                                      <ng-container *ngIf="event.workout">
                                                        <span *ngIf="event.workout.expectedTime != null">{{ event.workout.expectedTime | secsToHourMinOnly }}</span>
                                                        <span *ngIf="(event.workout.expectedMiles || event.workout.expectedKilometers)">{{ ((event.workout.expectedMiles | milesKilometersToMiles: event.workout.expectedKilometers) | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                                                        <span class="d-block">{{event.workout.expectedTrainingLoad}} (load)</span>
                                                      </ng-container>
                                                      <ng-container *ngIf="event.eventType == eventType.QuickWorkout">
                                                        <span *ngIf="event.quickWorkoutDuration != null">{{ event.quickWorkoutDuration | secsToHourMinOnly }}</span>
                                                        <span *ngIf="event.quickWorkoutDistance">{{ (event.quickWorkoutDistance | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                                                        <span class="d-block">{{event.quickWorkoutExpectedTrainingLoad}} (load)</span>
                                                      </ng-container>
                                                    </div>
                                                    <div class="custom-event-title-wrapper">
                                                      <span class="event-title">{{ event.workoutName }}</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </ng-container>
                                            </td>
                                            <td class="totals" style="word-wrap: break-word">
                                              <div class="activity-type-list">
                                                <div *ngFor="let type of week.activityTypeDurations; let t = index" class="activity-type">
                                                  <i class="far {{ type.activityType | customCalendarEventIcon: null:null }}"></i>
                                                  <span>{{ type.duration | secsToHourMinOnly }}</span>
                                                  <span>{{ (type.distance | fromMilesToLocalMeasurement) | number: '1.2-2' }}{{localMeasurementName}}</span>
                                                  <span class="d-block">{{ type.trainingLoad }} (load)</span>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!isPreview">
                <button type="button" (click)="addWeek(week.weekNumber)" class="btn btn-primary btn-sm mt-1 ml-1">Add Week</button>
              </div>
            </div>
          </div>

          <div class="row form-btns">
            <div class="col">
              <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
            </div>
            <div *ngIf="!isPreview" class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Program</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<!-- Assign Program modal-->
<assign-program *ngIf="openAssignProgramDialog" [programId]="currentProgramId" [programName]="currentProgramName" [(openAssignProgram)]="openAssignProgramDialog"></assign-program>
<assign-team-program *ngIf="openAssignTeamProgramDialog" [programId]="currentProgramId" [programName]="currentProgramName" [(openAssignProgram)]="openAssignTeamProgramDialog"></assign-team-program>
